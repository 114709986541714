import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text, Card, Icon, Stack, Button, HStack, Modal } from  '@arcault-ui/shared-components';
import { useDropzone } from 'react-dropzone';
import useAxiosWithCredentials from '../../hooks/useAxiosWithCredentials';
import './DocumentsViewer.css';
import useDocuments from '../../hooks/useDocuments';

/*export const docsConfig = [
    { name: "Last Will & Testament", label: "Last Will & Testament", multiple: false, link: '/profile/estate/executor' },
    { name: "Living Will", label: "Health Agent", multiple: false, link: '/profile/estate/healthcareproxy' },
    { name: "Trust", label: "Financial Power of Attorney", multiple: true, link: '/profile/estate/poa' },
    { name: "Trust", label: "Financial PoA", multiple: true, link: '/profile/estate/poa' },
];*/


const NeededDocument = ({ documents, docType }) => {
    const axiosWithCredentials = useAxiosWithCredentials();
    //console.log('doctype', docType)

    const onAddDocument = async (fileId) => {
        try {
           // console.log('onAddDocument', fileId, docType);
            await axiosWithCredentials.post(`/fo/files/estate/${fileId}/tag/${docType.name}`);
            console.log('fileId', fileId);
          } catch (error) {
            console.error(`Failed to add tag ${'estate-planning'} to file ${fileId}:`, error);
          }  
    };

    const {
        DropArea,
        UploadMechanism,
    } = useDocuments({ initialDocuments: documents, category: 'estate', addCallBack: onAddDocument });

    return (
        <Card key={docType.name} className={`document-item ${docType.style || 'ghost'} ${docType.required ? 'dot' : ''}`}>
            <DropArea>
                <Stack variant="stretch" className="document-item-content">
                    <Text variant="caption">{docType.label}</Text>
                    <UploadMechanism><Icon name="upload" /></UploadMechanism>
                </Stack>
            </DropArea>
        </Card>
    )
};

const DocumentsViewer = ({ requiredDocuments, navigate }) => {
    const [allDocuments, setAllDocuments] = useState([])

    const axiosWithCredentials = useAxiosWithCredentials();

  const {
    documents,
    deleteDocument,
  } = useDocuments({ category: 'estate' });

  const loadDocs = async () => {
    /*let newDocs = [];
    for (const category of Object.keys(requiredDocuments)) {
        const docResponse = await axiosWithCredentials.get('files/' + category + '/');
        newDocs = [ ...newDocs, ...docResponse.data ];*/
    //}

    setAllDocuments(documents);
  }

  useEffect(() => {
    loadDocs()
  }, [documents]);

  const matchedDocs = Object.keys(requiredDocuments).map(id => ({ ...requiredDocuments[id], id, name: id })).reduce((acc, docType) => {
    //console.log('docType', docType);
    const matchedDocuments = allDocuments.filter(doc => doc.type === docType.id);
    acc.push({ docType, documents: matchedDocuments });
    return acc;
  }, []);

  return (
    <Card title="Documents" className="documents-container your-vault-card" footer={(
        <HStack variant="end"><Button onClick={() => navigate('/documents')} type="outline">View all</Button></HStack>
    )}>
        <div className="mobile-doc-viewer">
            <Text variant="h4">Documents</Text>
            <Text variant="h4">{matchedDocs.length} docs</Text>
        </div>
        <Stack variant="stretch">
            <div className="documents-list">    
                {matchedDocs.map(({ docType, documents }) => (
                    documents.length > 0 ? documents.map(doc => (
                    <div key={doc.id} className="document-item">
                        <Text variant="body">{doc.fileName}</Text>
                        <Text variant="caption">{docType.label}</Text>
                        <Button onClick={() => deleteDocument(doc.id)}>Delete</Button>
                    </div>
                    )) : (
                        <NeededDocument documents={documents} docType={{ ...docType, required: true }} />
                    )
                ))}
                <NeededDocument documents={documents} docType={{
                    label: 'Other documents',
                    name: 'estate',
                    style: 'outline'
                }}/>

            </div>
        </Stack>
    </Card>
  );
};

DocumentsViewer.propTypes = {
  docsConfig: PropTypes.array.isRequired,
  onAddDocument: PropTypes.func.isRequired,
};

export default DocumentsViewer;