import { useState, useEffect } from 'react';

import { Text, Button } from '@arcault-ui/shared-components';

import useAxiosWithCredentials from '../../../hooks/useAxiosWithCredentials';
import {useSettings} from '../../../context/SettingsContext';
import SecretDialog from './SecretDialog';
import FormDialog from './FormDialog';
import AssetsTable from '../assetsTable/AssetsTable';

import './infoAssets.css';
import Section from '../Section';
import SpreadLine from '../SpreadLine';


const InfoAssetsTable = ({ addTitle, tableFields, handleFormChangeCallback, onDelete, title, fields, onAdd, onEdit, service, dialogTitle = "" }) => {
  
  const axiosWithCredentials = useAxiosWithCredentials();
  const {settings} = useSettings();
  
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const [editingSecret, setEditingSecret] = useState(false);
  const [viewingSecret, setViewingSecret] = useState(false);
  const [secret, setSecret] = useState({}); // Manage the secret value
  
  // Handle changes in the plaintext (JSON string) representation
  const handleSecretChange = (event) => {
    setSecret({ ...secret, [event.target.name]: event.target.value });
  };

 

  useEffect(() => {
      loadItems();
    }, []); 
  
  const loadItems = async () => {
    try {
      // Replace this URL with your actual endpoint
      let response;
      let dataArray = [];
      if (!Array.isArray(service)) {
        response = await axiosWithCredentials.get(`/fo/${service}/info`);
        dataArray = response.data;
      } else {
        for (const svc of service) {
          response = await axiosWithCredentials.get(`/fo/${svc}/info`);
          dataArray = [...dataArray, ...response.data.map((item) => ({...item, service: svc}))];
        }
      }

      console.log("LOAD ITEMS" , dataArray)
      setRows(dataArray);
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
  };
  
  // Handle the delete action
  const handleDelete = async (row) => {
    if(onDelete){
      return onDelete(row)
    }
    try {
      const response = await axiosWithCredentials.delete(`/fo/${service}/info/${row.id}`);
      if (response.status === 200 || response.status === 201) {
        console.log('Delete successful');
        loadItems();
      } else {
        console.error('Delete failed');
        // Handle the upload failure (e.g., display error message)
      }
    } catch (error) {
      console.error('Error during Delete', error);
      // Handle any errors that occurred during the upload process
    }
  };
  const handleOpen = (item) => {
        if (handleFormChangeCallback) handleFormChangeCallback(formData)
        if(item){
           setFormData(item)
           setEditMode(true);
        }
        else{
          setFormData({})
          setEditMode(false);
        }
        setOpen(true)
    };
    
    const handleOpenSecret = async (item) => {
        setFormData(item)
        console.log(formData)
        if(item.secretCreatedOn){
          const uri = `/fo/secret/${service}/${item.sortKey}`
          const response = await axiosWithCredentials.get(uri);
          const data = response.data

          setSecret({...data, plaintext: data.ciphertextBlob.data})
          setEditingSecret(false)
        }
        else{
          //New secrets should start of ready to edit
          setEditingSecret(true)
          setSecret({})
        }
        setViewingSecret(true);
    };
    
    
  const handleClose = () => {
        setOpen(false);
        setFormData({});
    };
    
  const handleSave = async () => {
    if (editMode) {
        if(onEdit){
          onEdit(formData)
          handleClose();
          return null;
        }
        try {
          const response = await axiosWithCredentials.put(`/fo/${formData.service || service}/info/${formData.id}`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Update successful');
            loadItems();
          } else {
            console.error('Update failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Update', error);
          // Handle any errors that occurred during the upload process
        }
    } else {
        if(onAdd){
          onAdd(formData)
          handleClose();
          return null;
        }
        try {
          const response = await axiosWithCredentials.post(`/fo/${formData.service || service}/info`, formData);
          if (response.status === 200 || response.status === 201) {
            console.log('Create successful');
            loadItems();
          } else {
            console.error('Create failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Create', error);
          // Handle any errors that occurred during the upload process
        }
    }
    handleClose();
    };
  const handleFormChange = (event) => {
    const newFormData = {...formData, [event.target.name]: event.target.value }
    setFormData(newFormData);
    if (handleFormChangeCallback) handleFormChangeCallback(newFormData)
  };
  
    
  const saveSecret = async () => {
    if (formData.secretCreatedOn) {
        try {
          const response = await axiosWithCredentials.put(`/fo/secret/${service}/${formData.sortKey}`, secret);
          if (response.status === 200 || response.status === 201) {
            loadItems();
            console.log('Secret Update successful');
            
          } else {
            console.error('Secret Update failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Secret Update ', error);
          // Handle any errors that occurred during the upload process
        }
    } else {
        try {
          const response = await axiosWithCredentials.post(`/fo/secret/${service}/${formData.sortKey}`, secret);
          if (response.status === 200 || response.status === 201) {
            loadItems();
            console.log('Secret Create successful');
          } else {
            console.error('Secret Create failed');
            // Handle the upload failure (e.g., display error message)
          }
        } catch (error) {
          console.error('Error during Secret Create', error);
          // Handle any errors that occurred during the upload process
        }
    }
    setEditingSecret(false)
    setViewingSecret(false)
    };
    
    
  const viewSecret = async () => {
    try {
      const response = await axiosWithCredentials.get(`/fo/secret/${service}/${formData.sortKey}`);
      console.log(JSON.stringify(response))
      setSecret(response.data)
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
    setViewingSecret(true);
  }
  
  const addSecret = () => {
    setViewingSecret(true)
  }
  const handleCloseSecret = () => {
    setViewingSecret(false)
    setEditingSecret(false)
    
  }
  const cancelEditSecret = () => {
    setViewingSecret(false)
    setEditingSecret(false)
  }
  const handleEditSecret = async () => {
    
    try {
      console.log(JSON.stringify(secret))
      const response = await axiosWithCredentials.get(`/fo/d/${secret.sortKey}`);
      console.log(JSON.stringify(response))
      
      if(response.data.plaintext){
        setSecret({...secret, plaintext: response.data.plaintext})
      }
      else{
        setSecret({...secret, plaintext: response.data.ciphertextBlob, infoMessage: "You used a private key"})
      }
      
    } catch (error) {
      console.error("Error loading files:", error);
      // Handle errors appropriately
    }
    setEditingSecret(true)
  }
  function displayValue(value, displayType) {
  switch (displayType) {
    case "masked":
      const valueStr = value.toString();
      if (valueStr.length <= 4) {
        return valueStr; // Return the value as is if it's 4 or fewer characters
      }
      // Mask all but the last 4 characters
      return `${'*'.repeat(valueStr.length - 4)}${valueStr.slice(-4)}`;

    case "currency":
      // Assuming USD for example; adjust 'en-US' and 'USD' as needed
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // You can add more options here to control the display
      }).format(value);

    default:
      return value;
  }
}
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading policies: {error}</p>;

  const columns = (tableFields || fields).map((field) => ({
    id: field.id,
    label: field.label,
    align: field.align || 'left',
  }));

  const actions = [
    {
      label: 'edit',
      iconName: 'edit',
      onClick: handleOpen,
    },
    {
      label: 'delete',
      iconName: 'delete',
      onClick: handleDelete,
    },
    {
      label: 'secret',
      iconName: 'key',
      onClick: handleOpenSecret,
    },
  ];

    
  return (
    <Section>
      <SpreadLine>      
        <Text variant="subheading" component="div" sx={{ p: 2 }}>{title}</Text>
        <Button aria-label="add" type="primary" onClick={() => handleOpen()}>{addTitle}</Button>
      </SpreadLine>
      <div>
      <AssetsTable
        columns={columns}
        rows={rows}
        actions={actions}
        displayValue={displayValue}
      />
      </div>
      <FormDialog
        open={open}
        handleClose={handleClose}
        editMode={editMode}
        dialogTitle={dialogTitle}
        fields={fields}
        formData={formData}
        handleFormChange={handleFormChange}
        handleSave={handleSave}
      />
      <SecretDialog
          viewingSecret={viewingSecret}
          handleCloseSecret={handleCloseSecret}
          settings={settings}
          secret={secret}
          editingSecret={editingSecret}
          handleSecretChange={handleSecretChange}
          handleEditSecret={handleEditSecret}
          cancelEditSecret={cancelEditSecret}
          saveSecret={saveSecret}
        />
    </Section>
  );
};

export default InfoAssetsTable;