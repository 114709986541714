import { axiosWithCredentials } from "../api/axios";
import { fetchAuthSession } from '@aws-amplify/auth';

const setupAxiosWithCredentials = () => {
    let authSession = null;

    const requestIntercept = axiosWithCredentials.interceptors.request.use(
        async config => {
            console.log('setupAxiosWithCredentials requestIntercept', authSession);
            let session;
            if (!authSession) {
                console.log('setupAxiosWithCredentials requestIntercept refetching', authSession);
                session = await fetchAuthSession();
            } else {
                session = authSession;
            }
            if (session) {
                if (!authSession) authSession = session;
                config.headers['Authorization'] = `Bearer ${session.tokens.idToken}`;
            }
            return config;
        }, (error) => Promise.reject(error)
    );

    const responseIntercept = axiosWithCredentials.interceptors.response.use(
        response => response,
        error => {
            // Handle error response
            return Promise.reject(error);
        }
    );

    return () => {
        axiosWithCredentials.interceptors.request.eject(requestIntercept);
        axiosWithCredentials.interceptors.response.eject(responseIntercept);
    };
}

export default setupAxiosWithCredentials;
