import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = '/engage/rfp';

class RFP extends Base {
    async draft() {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = await axiosWithCredentials.get(BASE_URL + '/draft?journey=estate_plan');
            return data;
        } finally {
            removeInterceptors();
        }
    }

    async get(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const data = id ? 
                await axiosWithCredentials.get(this.base_url + '?id=' + id) : 
                await axiosWithCredentials.get(this.base_url)
            return data;
        } finally {
            removeInterceptors();
        }
    }


    async publish(id) {
        const removeInterceptors = setupAxiosWithCredentials();
        try {
            const response = await axiosWithCredentials.post(BASE_URL + '/publish/' + id);
            return response;
        } finally {
            removeInterceptors();
        }
    }
}

export const rfpAPI = new RFP(BASE_URL);
