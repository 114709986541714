import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react'
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';



const defaultSettings = {
  darkMode : true,
  notifications: true,
  secrets: {
    useCustomEncryption: false, // Default value
  },
}


const SettingsContext = createContext({ settings: {}, updateSettings: () => {}, basicInfo: {}, updateBasicInfo: () =>{} });


export const SettingsProvider = ({ children }) => {
    
  const [settings, setSettings] = useState(defaultSettings);
  const [basicInfo, setBasicInfo] = useState({});
  
  const [wizardState, setWizardState] = useState(null);
  const [returnToState, setReturnToState] = useState(null);
  const axiosWithCredentials = useAxiosWithCredentials();
  

  const {route} = useAuthenticator(context => [context.route ])


  // Function to update settings
  const updateBasicInfo = async (newInfo) => {
    try {
      
      setBasicInfo((prevInfo) => ({
      ...prevInfo,
      ...newInfo,
    }));
    
    await axiosWithCredentials.put(`/fo/user/basic/me`, newInfo);
    
    
    } catch (error) {
      console.error('Failed to update basic info:', error);
      // Handle error (e.g., show a notification)
    }
  };
    // Function to update settings
  const updateSettings = async (newSettings) => {
    try {
      
      setSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }));
    
    await axiosWithCredentials.put(`/fo/arcault/config/app`, newSettings);
    
    
    } catch (error) {
      console.error('Failed to update settings:', error);
      // Handle error (e.g., show a notification)
    }
  };
  
useEffect(() => {
  // if (route !== "authenticated") return;
   //if (settings && basicInfo) return;
   const fetchSettings = async () => {
    try {
      
      const response = await axiosWithCredentials.get(`/fo/arcault/config/app`);
      const settings = response.data
      return settings;
      
    } catch (error) {
      console.error('Fetching settings failed:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  const fetchBasicInfo = async () => {
    console.log('fetching basic info')
    try {
      
      const response =  await axiosWithCredentials.get('/fo/user/basic/me', {});
      const basicInfo = response.data
      return basicInfo;
      
    } catch (error) {
      console.error('Fetching basic info failed:', error);
      throw error; // Re-throw the error to be handled by the caller
    }
  }


  const fetchAndSetSettings = async () => {
    try {
      // Fetch settings and basic info
      const fetchedSettings = await fetchSettings();
      setSettings(fetchedSettings);

      const fetchedInfo = await fetchBasicInfo();
      setBasicInfo(fetchedInfo);
    } catch (error) {
      console.error('Error fetching settings or basic info:', error);
    }
  };

  fetchAndSetSettings();
}, [route]);

  return (
    <SettingsContext.Provider value={{  wizardState, setWizardState,returnToState, setReturnToState, settings, updateSettings, basicInfo, updateBasicInfo }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => useContext(SettingsContext);


