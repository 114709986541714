import React, { useState, useEffect } from "react";
import useAxiosWithCredentials from '../hooks/useAxiosWithCredentials';
import { Card, Text } from "@arcault-ui/shared-components";
import { signOut, getCurrentUser, fetchUserAttributes, fetchAuthSession , decodeJWT} from 'aws-amplify/auth';
import './Creds.css';

const Creds = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userAttr, setUserAttr] = useState(null);
  
  
  const [error, setError] = useState(null);
  
  const [copySuccess, setCopySuccess] = useState('');
  const axiosWithCredentials = useAxiosWithCredentials()  

    const deleteEverything = async () => {
    // Show confirmation dialog
    const confirmDelete = window.confirm("Are you sure you want to delete everything? This action cannot be undone.");

    if (!confirmDelete) {
      console.log("Delete action cancelled by user.");
      return; // Exit if user cancels
    }

    try {
      const response = await axiosWithCredentials.delete('/fo/everything');
      console.log("Successfully deleted everything:", response);
      const resetResponse = await axiosWithCredentials.put('/ajourney/persona/reset', {});
      console.log("Successfully  reset persona", resetResponse);
      alert("All data has been deleted successfully."); // Optional success message
    } catch (error) {
      console.error("Error deleting everything:", error);
      alert("An error occurred while deleting data."); // Optional error message
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy!');
    });
  };


  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        // Get the current authenticated user's session
        const session = await fetchAuthSession();
        
        setIdToken(session.tokens.idToken.toString())
        setAccessToken(session.tokens.accessToken.toString());
        
        let userAtt = await fetchUserAttributes(session.tokens.idToken)
        setUserId(userAtt["custom:userId"])
        setUserAttr(JSON.stringify(userAtt))
        
      } catch (err) {
        console.error("Error fetching access token:", err);
        setError("Unable to fetch access token. Please log in.");
      }
    };

    fetchAccessToken();
  }, []);

  return (
    <div style={{ width: 800 }}>
    <Card display="flex" flexWrap="wrap" gap={1} mb={2}>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Access Token</Text>
        {accessToken ? (
          <>
            <Text variant="access-token">{accessToken}</Text>
            <button onClick={() => copyToClipboard(accessToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">Id Token</Text>
        {idToken ? (
          <>
            <Text variant="access-token">{idToken}</Text>
            <button onClick={() => copyToClipboard(idToken)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching access token..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">User Id</Text>
        {userId ? (
          <>
            <Text variant="access-token">{userId}</Text>
            <button onClick={() => copyToClipboard(userId)}>Copy to Clipboard</button>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching userId..."}
          </Text>
        )}
      </div>
      <div style={{ paddingBottom: 36 }}>
        <Text variant="h6">User Attributes</Text>
        {userAttr ? (
          <>
            <Text>{userAttr}</Text>
          </>
        ) : (
          <Text color="error">
            {error || "Fetching user attributes..."}
          </Text>
        )}
      </div>
      <div>
      <h1>Delete All Data</h1>
      <button onClick={deleteEverything} style={{ backgroundColor: 'red', color: 'white', padding: '10px', border: 'none', cursor: 'pointer' }}>
        Delete Everything
      </button>
    </div>
      
    </Card>
    </div>
  );
};

export default Creds;