import setupAxiosWithCredentials from "../utils/setupAxiosWithCredentials";
import { axiosWithCredentials } from "../api/axios";
import Base from './base';

const BASE_URL = '/ajourney/datagent/estate_planning';

class DataAgent extends Base {

}

export const dataAgentAPI = new DataAgent(BASE_URL);
